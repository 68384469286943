

export default {
    name : 'PsRouteLink',
    props: {
        textColor : {
            type: String,
            default: 'text-secondary-500 dark:text-secondaryDark-white'
        },
        textSize : {
            type : String,
            default : 'text-base'
        }
    }
}
