<template>
  <section class="header">
    <div class="container">
      <nav class="navbar navbar-expand-lg navbar-light">
        <ps-route-link :to="{name: 'dashboard'}"
                                        class="navbar-brand logo">
          <img src="@/assets/images/logo.png" class="logo1">
        </ps-route-link>

        <button @click="showCollapse" class="navbar-toggler" type="button" data-toggle="collapse"
                data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false"
                aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"
                style="filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(0deg) brightness(100%) contrast(100%);"></span>
        </button>
        <div :class="collapseToggle?'show':''" class="collapse navbar-collapse" id="navbarSupportedContent"
             @mouseout="hidecollapse">
          <ul class="navbar-nav">
            <li class="nav-item active" @mouseover="closeList">
              <ps-route-link :to="{name: 'dashboard'}" class="">{{
                  $t("home")
                }}
                </ps-route-link>
            </li>
            <li class="nav-item dropdown" v-if="categoryProvider.itemList.data != null">
              <a @mouseover="showList" class="" id="navbarDropdown" role="button" data-toggle="dropdown"
                 aria-haspopup="true" aria-expanded="false">
                {{ $t("Invest_now!") }}
              </a>
              <div v-show="listToggle" @mouseleave="closeList">
                <ul class="dropdown-menu d-block" aria-labelledby="navbarDropdown">
                  <li class="" v-for="category in categoryProvider.itemList.data.slice(0,7)" @click="toggleMobileMenu"
                      :key="category.catId">
                    <ps-route-link  :to="'item-list?cat_id=' + category.catId +'&cat_name=' + getCategoryName(category)">
                      {{ getCategoryName(category) }}
                    </ps-route-link>
                  </li>
                </ul>
              </div>
            </li>
            <li class="nav-item" @mouseover="closeList" @click="toggleMobileMenu">
              <ps-route-link  :to="{name: 'townships'}" class="">{{ $t("Investment_areas") }}</ps-route-link>
            </li>
            <li class="nav-item" @mouseover="closeList" @click="toggleMobileMenu">
              <ps-route-link  to="/blog" class="">{{ $t("blogs") }}</ps-route-link>
            </li>
            <li class="nav-item" @mouseover="closeList" @click="toggleMobileMenu">
              <ps-route-link  to="/contact" class="">{{ $t("Contact_us") }}</ps-route-link>
            </li>
            <li class="nav-item" @mouseover="closeList" @click="toggleMobileMenu">
              <ps-route-link  to="/about" class="">{{ $t("About_Osool") }}</ps-route-link>
            </li>
            <li class="nav-item" v-if="!psValueHolder.isUserLoggedIn()" @click="toggleMobileMenu"
                @mouseover="closeList">
              <ps-route-link  to="/register" class="">{{ $t("Join as Developer") }}</ps-route-link>
            </li>
            <!-- <li class="nav-item" @mouseover="closeList" v-if="psValueHolder.isUserLoggedIn()"><ps-route-link :to="{name: 'item-entry'}" class="btn">{{ $t("ps_nav_bar__new_post") }}</ps-route-link></li> -->
            <!-- <li class="nav-item">
                <ps-route-link	s-route-link :to="{name: 'item-entry'}" class="btn" >
                    انشاء اعلان
                </ps-route-link>
            </li> -->
          </ul>
          <!-- <form v-if="psValueHolder.isUserLoggedIn()" class="form-inline my-2 my-lg-0" @click.prevent>
              <ps-route-link	s-route-link :to="{name: 'item-entry'}" class="btn" >
                  انشاء اعلان
              </ps-route-link>
          </form> -->
          <form class="form-inline my-2 my-lg-0" v-if="psValueHolder.isUserLoggedIn()" @click.prevent>
            <div @click="toggleMobileMenu" class="  h-12 flex items-center justify-center me-2 ">
              <ps-route-link
                  aria-label="Noti"

                  :to="{name: 'notification-list' }">
                <div class="icon-box" style="width: 25px;height: 24px;color:#fff !important;">
                  <ps-icon textColor="" name="bell" h="25" w="25"
                           style="width: 25px;height: 24px;color:#fff !important;"/>
                </div>
              </ps-route-link>

            </div>
            <div @click="toggleMobileMenu" class=" h-12 flex items-center justify-center me-4 ">
              <ps-route-link
                  aria-label="Message"

                  :to="{name: 'chat-list' }">
                <div class="icon-box">
                  <!-- <span > {{ messageCount ? messageCount :2 }}</span>   -->
                  <ps-icon textColor="" name="chatalt2" h="25" w="25"/>
                </div>
              </ps-route-link>
            </div>

            <div class="profile-list" @mouseleave="showUsersLeave">
              <div @mouseover="showUsers" class="icon-box">
                <img alt="Placeholder"
                     class="rounded-full bg-transparent w-8 h-8 flex items-center justify-center object-cover"
                     width='50px' height='50px' v-lazy="userProvider.imageUrl(userProfile, 1)"
                     @error="userProvider.defaultProfileImage">
                <div @mouseover="showUsers" v-show="usersToggle">
                  <ul @mouseover="showUsers" class="dropdown-menu d-block profile-info-list">
                    <li @click="toggleMobileMenu">
                      <ps-route-link

                          textSize="text-sm"
                          :to="{name: 'profile' }"> {{ $t("profile") }}
                      </ps-route-link>
                    </li>
                    <li @click="toggleMobileMenu">
                      <ps-route-link

                          textSize="text-sm"
                          :to="{name: 'favourite' }"> {{ $t("favourite_list__favourite_list") }}
                      </ps-route-link>
                    </li>
                    <li @click="clickLogout">
                      <ps-route-link

                          textSize="text-sm"
                          :to="{name: 'favourite' }"> {{ $t("ps_nav_bar__logout") }}
                      </ps-route-link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </form>

          <form v-else class="form-inline my-2 my-lg-0" @click.prevent>
            <button @click="loginClicked" class="btn">
              {{ $t("ps_nav_bar__login") }}
            </button>
            <button @click="registerClicked" class="btn">{{ $t("register") }}</button>
          </form>
        </div>
      </nav>
    </div>

  </section>
  <!-- end header-->
</template>

<script>
// import PsUtils from '@/utils/PsUtils';

import {defineComponent, ref, onMounted} from "vue";
import {useUserProviderState} from '@/store/modules/user/UserProvider';
import {PsValueProvider} from '@/store/modules/core/PsValueProvider';
import {useCategoryProviderState} from "@/store/modules/category/CategoryProvider";

import router from '@/router';
import Velocity from "velocity-animate";
//import $ from "cash-dom";
import {createUserUnReadMessageProviderState} from "@/store/modules/chat/UserUnReadMessageProvider";
import UserUnReadMessageParameterHolder from '@/object/holder/UserUnReadMessageParameterHolder';
import PsIcon from '@/components/core/icons/PsIcon.vue';

// import PsRouteLink from '@/components/core/link/PsRouteLink.vue';
import {useRoute} from "vue-router";
import {i18n} from '@/assets/locales/index';
// import PsRouteLinkWithClickEvent from "@/components/core/link/PsRouteLinkWithClickEvent.vue";
import PsRouteLink from "@/components/core/link/PsRouteLink.vue";

export default defineComponent({
  components: {
    PsRouteLink,
    // PsRouteLink,
    PsIcon,
    // PsRouteLinkWithClickEvent
  },
  props: {
    topOfPage: {
      type: Boolean,
      default: false
    }
  },
  setup() {
    // const activeMobileMenu = ref(false);


    const userProvider = useUserProviderState();
    const userProfile = ref();
    const currentLocale = i18n.global.locale;
    const messageCount = ref(0);
    const userunreadmsgProvider = createUserUnReadMessageProviderState();
    const userUnreadHolder = new UserUnReadMessageParameterHolder();
    const usersToggle = ref(false);
    const categoryProvider = useCategoryProviderState();
    const listToggle = ref(false);
    const collapseToggle = ref(false);
    const hideTimer = ref();
    const showList = () => {
      listToggle.value = !listToggle.value;
    };

    const closeList = () => {
      listToggle.value = false;
    };

    function getCategoryName(category) {
      return this.currentLocale === 'ar' ? category.catName : category.catNameEN;
    }

    const showUsers = () => {
      usersToggle.value = true;
      clearTimeout(hideTimer.value);
    };
    const showUsersLeave = () => {
      hideTimer.value = setTimeout(() => {
        usersToggle.value = false;
      }, 70);
    };

    const showCollapse = () => {
      collapseToggle.value = !collapseToggle.value;
    };

    const hidecollapse = () => {
      collapseToggle.value = false;
    }

    let psValueHolder = PsValueProvider.psValueHolder;

    userUnreadHolder.userId = psValueHolder.loginUserId;


    if (psValueHolder.isUserLoggedIn()) {
      loadUser()

    }

    async function loadUser() {
      await userProvider.getUser(psValueHolder.loginUserId);
      userProfile.value = userProvider.user.data.userProfilePhoto;
      // console.log(userProfile.value);
      // console.log(userProvider.user.data);
    }

    async function loadMessage() {
      await userunreadmsgProvider.postUserUnreadMessageCount(userUnreadHolder);
      messageCount.value = parseInt(userunreadmsgProvider.buyingitemList.data?.buyerUnreadCount) + parseInt(userunreadmsgProvider.buyingitemList.data?.sellerUnreadCount);
    }

    loadMessage();
    onMounted(async () => {
      await loadUser;
      await loadMessage;
    })

    function userNameClicked() {
      PsValueProvider.psValueHolder.loadData();
      psValueHolder = PsValueProvider.psValueHolder;
    }

    function gotToHome() {
      router.push({name: "dashboard"});
    }

    function toggleMobileMenu(bol = true) {
      if (bol) {

        collapseToggle.value = !collapseToggle.value;
      }
    }

    function enter(el, done) {
      Velocity(
          el,
          "slideDown",
          {
            duration: 300
          },
          {
            complete: done
          }
      );
    }

    function leave(el, done) {
      Velocity(
          el,
          "slideUp",
          {
            duration: 300
          },
          {
            complete: done
          }
      );
    }

    function clickLogout() {
      psValueHolder.logout();
      router.push({name: "dashboard"});
      collapseToggle.value = false;
    }

    const route = useRoute();

    function registerClicked() {

      if( collapseToggle.value){
        collapseToggle.value = false;
      }

      let tempParams = {};
      tempParams = route.query;
      tempParams['redirect'] = route.name;
      router.push({name: 'register', query: tempParams, params: route.params});
    }

    function loginClicked() {

      if (collapseToggle.value){
        collapseToggle.value = false
      }

      let tempParams = {};
      tempParams = route.query;
      tempParams['redirect'] = route.name;
      router.push({name: 'login', query: tempParams, params: route.params});
    }

    //light / dark mode
    const isDarkMode = ref(true);

    function toggleDarkMode() {

      if (localStorage.isDarkMode != null && localStorage.isDarkMode == 'true') {
        localStorage.isDarkMode = 'false';
        isDarkMode.value = false;
      } else {
        localStorage.isDarkMode = 'true';
        isDarkMode.value = true;
      }
      loadIsDarkMode();
    }

    loadIsDarkMode();

    function loadIsDarkMode() {

      if (localStorage.isDarkMode != null && localStorage.isDarkMode == 'true') {
        document.documentElement.classList.add('dark')
        isDarkMode.value = true;
      } else {
        document.documentElement.classList.remove('dark')
        isDarkMode.value = false;
      }

    }

    categoryProvider.loadItemList(psValueHolder.loginUserId, categoryProvider.paramHolder);

    return {
      toggleDarkMode,
      categoryProvider,
      userunreadmsgProvider,
      closeList,
      messageCount,
      userProfile,
      isDarkMode,
      psValueHolder,
      userProvider,
      gotToHome,
      hideTimer,
      enter,
      leave,
      toggleMobileMenu,
      clickLogout,
      loginClicked,
      userNameClicked,
      registerClicked,
      showUsers,
      showUsersLeave,
      usersToggle,
      listToggle,
      showList,
      collapseToggle,
      showCollapse,
      hidecollapse,
      getCategoryName,
      currentLocale
    }

  }
});
</script>
<style scoped>
.navbar #navbarSupportedContent {
  padding-right: 50px;
}

.lang-ltr .navbar .navbar-nav {
  margin-right: auto;
}

.lang-rtl .navbar .navbar-nav {
  margin-left: auto;
}

.navbar .navbar-nav .nav-item a {
  font-size: 16px;
  line-height: 50px;
  color: #fff;
  padding: 0 3px;
  margin: 0 5px;
  position: relative;
  transition: all 0.3s;
}

.navbar .navbar-nav > li > a:hover {
  color: #FFC124 !important;
  background-color: transparent;
}

.navbar .navbar-nav > li > a:before {
  content: "";
  width: 6px;
  height: 6px;
  border-radius: 50px;
  background-color: #FFC124;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  transition: all .3s;
}

.navbar .navbar-nav > li > a:after {
  content: "";
  width: 4px;
  height: 4px;
  border-radius: 50px;
  background-color: #FFC124;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  transition: all .3s;
}

.navbar .navbar-nav > li > a:hover:before {
  opacity: 1;
  top: calc(100% + 2px);
}

.navbar .navbar-nav > li > a:hover:after {
  opacity: 1;
  top: calc(100% - 4px);
}

.navbar .navbar-nav > li .btn:after,
.navbar .navbar-nav > li .btn:before {
  display: none;
}

.navbar .navbar-nav > li .btn {
  background: transparent;
}

.navbar .form-inline .btn {
  margin: 0 2px;
}

.navbar .icon-box {
  width: 50px !important;
  height: 50px !important;
  display: inline-block;
  padding: 0;
  border: 2px solid #fff;
  border-radius: 50px;
  line-height: 50px;
  text-align: center;
  color: #fff;
  font-size: 18px;
  cursor: pointer;
}

.navbar .icon-box .icon {
  margin: 0 auto;
  margin-top: 20%;
}

.navbar .icon-box img {
  margin: 0 auto;
  margin-top: 20%;
}

.navbar .dropdown-menu {
  text-align: right;
  color: #fff;
}

.lang-ltr .navbar .dropdown-menu {
  text-align: left;
  color: #fff;
}

.lang-rtl .navbar .dropdown-menu.profile-info-list {
  text-align: right;
  padding: 20px;
  left: 0;
}

.lang-ltr .navbar .dropdown-menu.profile-info-list {
  text-align: left;
  padding: 20px;
  left: unset;
  right: 6px;
}

.navbar .dropdown-menu.profile-info-list > li {
  padding: 5px;
}

.navbar .dropdown-menu > li > a {
  color: #FFF !important;
}

.navbar .dropdown-menu > li > a:hover {
  color: #FFC124 !important;
}

.header .navbar-light .navbar-toggler {
  color: rgb(255 193 36) !important;
  border-color: #ffc124 !important;
}
</style>