<template>
  <div :class="'float_icons' + (currentLocale==='en' ? ' right' : ' left')">
    <ul>
      <li>
        <ps-link style="background-color:#00e676;" v-bind:href="'https://api.whatsapp.com/send?phone='+phone">
          <font-awesome-icon :icon="{ prefix: 'fab', iconName: 'whatsapp' }" style="color:#FFF;font-size:18px;"
                             class="footer-icon-def"/>
        </ps-link>
      </li>
      <li>
        <ps-link v-bind:href="'tel:'+phone" style="background-color:dodgerblue;">
          <font-awesome-icon icon="phone" style="color:#FFF;font-size:18px;" class="footer-icon-def"/>
        </ps-link>
      </li>
      <li>
        <ps-link href="consult-expert" alt="استشر خبيرك" style="font-size: 10px;
                                                                            line-height: 15px;
                                                                            text-align: center;
                                                                            padding-top: 18px;"
                 class="footer-icon-def2">{{ $t("Consult your expert") }}
        </ps-link>
      </li>
    </ul>
  </div>
  <!-- end float_icons <i class="fa-solid fa-question"></i> -->
  <footer>
    <div class="container">
      <div class="row">
        <div class="col-xs-12 col-sm-5 col-md-4">
          <img src="@/assets/images/footer_logo.png" class="footer_logo">
          <p class="main_p">
            {{ $t("about_footer") }}
          </p>
        </div>
        <div class="col-xs-12 col-sm-5 col-md-4">
          <ul class="info" >
            <li>
              <font-awesome-icon icon="phone" style="color:#FFC124;margin-inline-end: 10px;"/>
              {{ phone }}
            </li>
            <li>
              <font-awesome-icon icon="envelope" style="color:#FFC124;margin-inline-end: 10px;"/>
              info@osoolrealestate.com
            </li>
            <li>
              <font-awesome-icon icon="map" style="color:#FFC124;margin-inline-end: 10px;"/>
              Cairo Egypt
            </li>

          </ul>
        </div>
        <div class="col-xs-12 col-sm-5 col-md-4">
          <h4> {{ $t("Quick links") }}</h4>
          <ul class="footer_links">
            <li>
              <ps-route-link
                  to="item-entry" textColor="text-textLight" textSize="font-light lg:text-sm text-xs">
                {{ $t("Create a post") }}
              </ps-route-link>
            </li>
            <li>
              <ps-route-link
                  :to="{name: 'contact' }" textColor="text-textLight" textSize="font-light lg:text-sm text-xs">
                {{ $t("about us") }}
              </ps-route-link>
            </li>
            <li>
              <ps-route-link
                  to="account-list" textColor="text-textLight" textSize="font-light lg:text-sm text-xs">
                {{ $t("developers") }}
              </ps-route-link>
            </li>
          </ul>
        </div>
      </div>

      <div class="copyright">
        <p>
          {{ $t("Developed by") }}
          <ps-link href="https://www.tqniait.com/">
            <img src="@/assets/images/tqnia_logo.png" alt="tqnia"
                 class="cursor-pointer "
                 style="display: inline;width: 68px;height: 25px;"/>
          </ps-link>
          {{ $t("All rights reserved") }}
        </p>
        <ul class="list-inline">
          <li>
            <ps-link :href="'https://api.whatsapp.com/send?phone='+phone">
              <font-awesome-icon :icon="{ prefix: 'fab', iconName: 'whatsapp' }" style="color:#FFF;font-size:26px"/>
            </ps-link>
          </li>
          <li>
            <ps-link url="https://www.facebook.com/OsoolEstate">
              <font-awesome-icon :icon="{ prefix: 'fab', iconName: 'facebook' }" style="color:#FFF;font-size:23px"/>
            </ps-link>
          </li>
          <li>
            <ps-link href="mailto:info@osoolrealestate.com">
              <font-awesome-icon icon="envelope" style="color:#FFF;font-size:23px"/>
            </ps-link>
          </li>
        </ul>
      </div>
    </div>
  </footer>
  <!-- end footer -->

</template>

<script>

// Libs
import {ref} from 'vue';
import router from '@/router';
import PsLink from '@/components/core/link/PsLink.vue'
import PsRouteLink from '@/components/core/link/PsRouteLink.vue'
//import PsLabelCaption from '@/components/core/label/PsLabelCaption.vue'
// Holders
import {PsValueProvider} from "@/store/modules/core/PsValueProvider";
import {useRecentItemProviderState} from "@/store/modules/item/RecentItemProvider";
import {usePopularItemProviderState} from '@/store/modules/item/PopularItemProvider';
import PsConfig from "@/config/PsConfig";
import {usePsAppInfoProviderState} from '@/store/modules/appinfo/AppInfoProvider';
import AppInfoParameterHolder from '@/object/holder/AppInfoParameterHolder';
import axios from 'axios';
import {onMounted, onUnmounted, onBeforeUnmount} from 'vue';
import {i18n} from '@/assets/locales/index';

export default {
  components: {
    PsLink,
    PsRouteLink,
    //PsLabelCaption
  },
  setup() {
    // Inject Item Provider
    const psValueHolder = PsValueProvider.psValueHolder;
    const locationId = ref(psValueHolder.locationId);
    const versionNo = ref(PsConfig.versionNo);

    const phone = ref();
    // Inject Item Provider
    const itempopularProvider = usePopularItemProviderState();
    const itemrecentProvider = useRecentItemProviderState();
    itempopularProvider.paramHolder.itemLocationId = locationId.value;
    const loginUserId = psValueHolder.getLoginUserId();
    const appInfoProvider = usePsAppInfoProviderState();
    const appInfoParameterHolder = new AppInfoParameterHolder();
    const currentLocale = i18n.global.locale;
    appInfoParameterHolder.userId = loginUserId;

    function gotToHome() {
      router.push({name: "dashboard"});
    }

    appInfoProvider.loadDeleteHistory(appInfoParameterHolder);

    function viewAllRecentItemList() {
      const params = itemrecentProvider.paramHolder.getUrlParamsAndQuery();
      params['name'] = 'item-list';

      router.push(params);


    }

    function viewAllPopularItemList() {

      const params = itempopularProvider.paramHolder.getUrlParamsAndQuery();
      params['name'] = 'item-list';

      // Redirect
      router.push(params);


    }

    async function getphone() {
      await axios.get(PsConfig.laravelUrl + 'phone')
          .then(function (response) {
            phone.value = response.data.phone;
          })
          .catch(function () {
          });
    }

    onMounted(() => {
      getphone();
    });
    onBeforeUnmount(() => {
    });
    onUnmounted(() => {
    });

    return {
      gotToHome,
      viewAllPopularItemList,
      viewAllRecentItemList,
      versionNo,
      PsConfig,
      phone,
      appInfoProvider,
      currentLocale
    }
  }
}
</script>
<style scoped>

.left{
  left: 20px;
}

.right{
  right: 20px;
}



@media (max-width: 746px ) {
  .copyright {
    flex-direction: column;
  }

  .copyright p{
    display: block;
    text-align: center;
    margin-bottom: 10px;
  }

}

</style>